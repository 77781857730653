import React from 'react';
import { Table } from 'react-bootstrap';
import { companies } from '../../shared/constants';
import { ellipsisTrunc } from '../../utils/string-utils';
import { Ellipsis } from '../shared/ellipsis';

export const IntegrationList = ({ integrations, onDelete }) => {
    const getCompanyName = companyValue => {
        for (const company of companies) {
            if (company.value === companyValue) return company.name;
        }

        return 'Unknown';
    };

    const renderIntegrationList = () => (
        <>
            <Table responsive bordered hover>
                {/*<table className="table table-bordered table-hover">*/}
                <thead>
                    <tr>
                        <th className={'col text-start'}>Name</th>
                        <th className={'col text-start'}>Account #</th>
                        <th className={'col text-start'}>Username</th>
                        <th className={'col text-start'}>API Token</th>
                        <th className={'col text-start'}>API Key</th>
                        <th className={'col text-start'}>Company</th>
                        <th className={'col text-start'}>Punchout Creation URL</th>
                        <th className={'col text-start'}>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {integrations.map((integration, i) => {
                        const truncatedApiKey = ellipsisTrunc(integration.apiKey, 13);
                        const truncatedApiToken = ellipsisTrunc(integration.apiToken, 13);
                        const truncatedCreatePunchoutUrl = ellipsisTrunc(integration.createPunchoutUrl, 30);

                        return (
                            <tr key={`${integration.name}_${integration.token}`}>
                                <td className={'text-start'}>{integration.name}</td>
                                <td className={'text-start'}>{integration.accountNo}</td>
                                <td className={'text-start'}>{integration.username}</td>
                                <td className={'text-start'}>
                                    <Ellipsis text={integration.apiToken} length={12} />
                                </td>
                                <td className={'text-start'}>
                                    <Ellipsis text={integration.apiKey} length={12} />
                                </td>
                                <td className={'text-start'}>{getCompanyName(integration.company)}</td>
                                <td className={'text-start'}>
                                    <Ellipsis text={integration.createPunchoutUrl} length={30} />
                                </td>
                                <td>
                                    <i className="bi-icon bi-trash" onClick={onDelete(integration)}></i>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </>
    );

    return integrations.length > 0 ? (
        renderIntegrationList()
    ) : (
        <div className="alert alert-primary" role="alert">
            No integrations to display
        </div>
    );
};

export default IntegrationList;
