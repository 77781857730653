import { useEffect } from 'react';
import axios from 'axios';

export const Logout = () => {
    useEffect(() => {
        axios.get('/api/logout').then(() => {
            window.location = '/login';
        });
    }, []);

    return null;
};
