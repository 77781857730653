export const isEmpty = text => text === '' || text === undefined || text === null;
export const isNotEmpty = text => !isEmpty(text);

export const ellipsisTrunc = (text, length) => {
    if (isNaN(Number(length)) || isEmpty(text) || text.length < length) {
        return text;
    }
    const ellipsisChars = '...';
    const truncatedText = text.substring(0, length - ellipsisChars.length);

    return `${truncatedText}${ellipsisChars}`;
};
