import { OverlayTrigger, Toast, ToastContainer, Tooltip } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { ellipsisTrunc } from '../../utils/string-utils';

/**
 * @typedef {Object} EllipsisProps
 * @property {string} text
 * @property {number} length
 */
/**
 * @param {EllipsisProps} props
 * @returns {JSX.Element}
 * @constructor
 */
export const Ellipsis = props => {
    const { text, length } = props;
    const truncatedText = ellipsisTrunc(text, length);
    const [isCopied, setIsCopied] = useState(false);
    const NOTIFICATION_TIME = 1500; // in millis

    useEffect(() => {
        let timeout;
        if (isCopied) {
            timeout = setTimeout(() => {
                setIsCopied(false);
            }, NOTIFICATION_TIME);
        }
        return () => clearTimeout(timeout);
    }, [isCopied]);

    const handleCopy = () => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                setIsCopied(true);
            })
            .catch(err => {
                alert(`Failed to copy text: ${err.message}`);
            });
    };

    const textStyle = isCopied ? { backgroundColor: 'yellow', transition: 'background-color 500ms ease-out' } : {};
    const cursorStyle = { cursor: 'pointer' };
    const styles = { ...cursorStyle, ...textStyle };

    return (
        <>
            <OverlayTrigger overlay={<Tooltip>{text}</Tooltip>}>
                <span style={styles} onClick={handleCopy}>
                    {truncatedText}
                </span>
            </OverlayTrigger>
            <ToastContainer className="p-3" position={'top-center'} style={{ zIndex: 1 }}>
                <Toast show={isCopied} bg={'success'}>
                    <Toast.Body className={'text-white'}>Copied to clipboard!</Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    );
};
