import React from 'react';
import { WorkOrderList } from '../work-order/work-order-list';
import { CustomerList } from '../customers/customer-list';
import { Card, Stack } from 'react-bootstrap';

export const Dashboard = () => {
    return (
        <Stack gap={3}>
            <Card>
                <Card.Body>
                    <h2>How to use this site</h2>
                    <div className="panel">
                        <ol>
                            <li>
                                Ensure you have your integration setup properly in the <a href={'/settings'}>Settings</a>
                            </li>
                            <li>Open a customer record</li>
                            <li>Start a new work order</li>
                            <li>Optionally, add parts from a canned job</li>
                            <li>Click "Get Parts" to punch out to speedDIAL</li>
                        </ol>
                    </div>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body>
                    <CustomerList />
                </Card.Body>
            </Card>
            <Card>
                <Card.Body>
                    <WorkOrderList />
                </Card.Body>
            </Card>
        </Stack>
    );
};
