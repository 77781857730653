import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

/**
 * @typedef {Object} InfoIconProps
 * @property {string} text
 */
/**
 * @param {InfoIconProps} props
 * @returns {JSX.Element}
 * @constructor
 */
export const InfoIcon = props => {
    return (
        <OverlayTrigger overlay={<Tooltip>{props.text}</Tooltip>}>
            <i className="bi bi-info-circle">&nbsp;</i>
        </OverlayTrigger>
    );
};
