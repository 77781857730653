import React from 'react';
import { Outlet } from 'react-router-dom';
import AuthGate from './auth/auth-gate';
import NavMenu from './nav-menu';

export const Layout = props => {
    return (
        <>
            <NavMenu isAuthenticated />
            <div className="container container-fluid" id={'app-container'}>
                <AuthGate>
                    <Outlet />
                </AuthGate>
            </div>
        </>
    );
};
