import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import axios from 'axios';

const AuthGate = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        if (!isAuthenticated) {
            axios
                .get('/api/authcheck')
                .then(() => setIsAuthenticated(true))
                .catch(() => setIsAuthenticated(false));
        }
    }, []);

    if (isAuthenticated) {
        return <Outlet />;
    } else {
        return null;
    }
};

export default AuthGate;
