import { Button, Modal } from 'react-bootstrap';
import { usd } from '../../utils/dom-utils';

export const NewQuotesDialog = props => {
    const { onClose, show, quotes } = props;

    return (
        <Modal show={show} onHide={onClose} size={'xl'}>
            <Modal.Header closeButton>
                <Modal.Title>New Product Quotes Received</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className={'table'}>
                    <thead>
                        <tr>
                            <th>Product ID</th>
                            <th>Product Desc</th>
                            <th>Product Price</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {quotes.map(quote => {
                            return (
                                <tr>
                                    <td>{quote.productID}</td>
                                    <td>{quote.productDesc}</td>
                                    <td>{usd(quote.price)}</td>
                                    <td>
                                        <Button size={'sm'} onClick={() => alert('Not yet implemented')}>
                                            + Add
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </Modal.Body>
        </Modal>
    );
};
