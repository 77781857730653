import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getFlatListVehicleDesc } from '../utils/vehicle';

export const CustomerList = props => {
    const [customers, setCustomers] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState({});

    useEffect(() => {
        axios.get('/api/customers').then(response => {
            const customers = response.data.data;

            setCustomers(customers);

            let vehiclesToSelect = {};

            customers.forEach(customer => {
                if (Array.isArray(customer.vehicles)) {
                    const vehicle = customer.vehicles[0];
                    vehiclesToSelect[customer._id] = vehicle._id;
                }
            });

            setSelectedVehicle(vehiclesToSelect);
        });
    }, []);

    const handleAddWorkOrder = (customer, vehicle) => event => {
        event.stopPropagation();
        event.preventDefault();

        const customerId = customer._id;
        const vehicleId = selectedVehicle[customerId];
        const postData = {
            customerId,
            vehicleId,
        };

        axios
            .post('/api/work-orders', postData)
            .then(response => {
                const number = response.data.data.number;

                window.location = `/work-orders/${number}`;
            })
            .catch(({ message }) => {
                alert('Something went wrong: ' + message);
            });
    };

    const handleVehicleChange = customer => event => {
        event.stopPropagation();
        event.preventDefault();

        let newSelectedVehicles = { ...selectedVehicle };
        newSelectedVehicles[customer._id] = event.target.value;

        setSelectedVehicle(newSelectedVehicles);
    };

    return (
        <div className={'customer-list'}>
            <h2>Customer List</h2>
            <table className={'table'}>
                <thead>
                    <tr>
                        <th className={'col text-start'}>Name</th>
                        <th className={'col text-start'}>Address</th>
                        <th className={'col text-start'}>Vehicle</th>
                        <th className={'col text-start'}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {customers.length > 0 &&
                        customers.map(customer => (
                            <tr key={`${customer.firstName} ${customer.lastName}`}>
                                <td className={'col text-start'}>{`${customer.firstName} ${customer.lastName}`}</td>
                                <td
                                    className={'col text-start'}
                                >{`${customer.address}, ${customer.city}, ${customer.state} ${customer.zipCode}`}</td>
                                <td className={'col text-start'}>
                                    <select
                                        className={'form-select'}
                                        name={'vehicle'}
                                        id={'vehicle'}
                                        onChange={handleVehicleChange(customer)}
                                    >
                                        {customer.vehicles.map(vehicle => {
                                            const desc = getFlatListVehicleDesc(vehicle);

                                            return (
                                                <option value={vehicle._id} key={desc}>
                                                    {desc}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </td>
                                <td className={'col text-start'}>
                                    <button
                                        className={'btn btn-success'}
                                        type={'submit'}
                                        onClick={handleAddWorkOrder(customer, customer.vehicles[0])}
                                    >
                                        <i className={'bi-icon bi-plus'} />
                                        Work Order
                                    </button>
                                </td>
                            </tr>
                        ))}
                    {customers.length === 0 && (
                        <tr>
                            <td colSpan={3}>No customers found</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};
