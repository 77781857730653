import React from 'react';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { Button, InputGroup } from 'react-bootstrap';

export const ProductQuoteSearchForm = props => {
    const { register, handleSubmit } = useForm();

    return (
        <form noValidate onSubmit={handleSubmit(props.onSearch)} className={'row g-3'}>
            <InputGroup className="mb-3">
                <Form.Control
                    type={'text'}
                    id={'searchTerm'}
                    {...register('searchTerm', { required: true })}
                    placeholder={'Search for products, e.g. 9006, 26ca, or gloves'}
                    required
                />
                <Button type={'submit'} id={'button-addon2'} disabled={props.disabled}>
                    <i className={'bi bi-search'}></i>
                </Button>
            </InputGroup>
        </form>
    );
};
