import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { usd } from '../../utils/dom-utils';
import { useNavigate } from 'react-router-dom';

export const WorkOrderList = props => {
    const [workOrders, setWorkOrders] = useState([]);
    const navigate = useNavigate();

    const getWorkOrders = () => {
        axios.get('/api/work-orders').then(response => {
            const workOrders = response.data.data;

            setWorkOrders(workOrders);
        });
    };

    useEffect(() => {
        getWorkOrders();
    }, []);

    const handleWorkOrderDelete = workOrder => event => {
        axios
            .delete(`/api/work-orders/${workOrder.number}`)
            .then(() => {
                getWorkOrders();
            })
            .catch(({ message }) => {
                console.error(message);
            });
    };

    const handleWorkOrderOpen = workOrder => event => {
        event.stopPropagation();
        event.preventDefault();

        navigate(`/work-orders/${workOrder.number}`);
    };

    return (
        <div className="work-order-list">
            <h2>Work Orders</h2>
            <table className={'table'}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Customer</th>
                        <th>Vehicle</th>
                        <th>Total</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {workOrders.map(workOrder => {
                        const { customer, vehicle } = workOrder;
                        const customerName = !!customer ? `${customer.firstName} ${customer.lastName}` : 'No Customer';
                        const vehicleInfo = !!vehicle
                            ? `${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.submodel}`
                            : 'No Vehicle';

                        return (
                            <tr key={workOrder.number}>
                                <td>
                                    <a href={`/work-orders/${workOrder.number}`} onClick={handleWorkOrderOpen(workOrder)}>
                                        {workOrder.number}
                                    </a>
                                </td>
                                <td>{customerName}</td>
                                <td>{vehicleInfo}</td>
                                <td>{usd(workOrder.partsTotal)}</td>
                                <td>
                                    <i className="bi-icon bi-trash" onClick={handleWorkOrderDelete(workOrder)}></i>
                                </td>
                            </tr>
                        );
                    })}
                    {workOrders.length === 0 && (
                        <tr>
                            <td colSpan={3}>No work orders found</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};
