import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import { Layout } from './components/layout';
import { Dashboard } from './components/dashboard/dashboard';
import { NoMatch } from './components/no-match';
import { Login } from './components/auth/login';
import { Logout } from './components/auth/logout';
import { PunchoutStart } from './components/punchout/punchout-start';
import { CustomerList } from './components/customers/customer-list';
import { WorkOrderList } from './components/work-order/work-order-list';
import { WorkOrder } from './components/work-order/work-order';
import { Seed } from './components/seed/seed';
import { Settings } from './components/settings/settings';

export const App = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    {process.env.NODE_ENV === 'development' && <Route path={'/seed'} element={<Seed />} />}
                    <Route path="/login" element={<Login />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path={'/punchouts/start/:code'} element={<PunchoutStart />} />
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Dashboard />} />
                        <Route path={'/customers'} element={<CustomerList />} />
                        <Route path={'/work-orders'} element={<WorkOrderList />} />
                        <Route path={'/work-orders/:number'} element={<WorkOrder />} />
                        <Route path={'/settings'} element={<Settings />} />

                        <Route path="*" element={<NoMatch />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    );
};
