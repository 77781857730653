import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

export const PunchoutStart = () => {
    const { code } = useParams();
    const [content, setContent] = useState(null);

    useEffect(() => {
        axios
            .post(`/api/punchouts/start/${code}`)
            .then(response => {
                window.location = '/punchouts/landing';
            })
            .finally(() => {
                setContent(
                    <div className="alert alert-danger" role="alert">
                        Your punchout session is not valid. Please close this window and try again.
                    </div>,
                );
            });
    }, [code]);

    return content;
};
