export const createPunchoutUrls = [
    { env: 'Prod', url: 'https://speeddial.worldpac.com/punchout/v1/create' },
    { env: 'UAT', url: 'https://speeddial-uat.worldpac.com/punchout/v1/create' },
    { env: 'QA', url: 'https://speeddial-qa.worldpac.com/punchout/v1/create' },
    { env: 'Dev', url: 'https://speeddial-dev.worldpac.com/punchout/v1/create' },
    { env: 'localhost', url: 'http://localhost:8080/punchout/v1/create' },
    { env: 'docker', url: 'http://host.docker.internal:8080/punchout/v1/create' },
];
export const apiKeys = {
    dev: 'MXUf5GvHJeQ8XyyPIOzfNgCu3s2UddOGHAASmiWn',
    prod: 'MEG2PQqbctUdNRdNUGLdzySqwxuaZ5D3OybM0Z6m',
};
export const companies = [
    { name: 'US', value: 'us-prd', apiKey: apiKeys.prod, country: 'US' },
    { name: 'Canada', value: 'ca-prd', apiKey: apiKeys.prod, country: 'CA' },
    { name: 'US QA', value: 'us-qa', apiKey: apiKeys.dev, country: 'US' },
    { name: 'Canada QA', value: 'ca-qa', apiKey: apiKeys.dev, country: 'CA' },
];

export const StorageKey = {
    SELECTED_INTEGRATION: 'selectedIntegration',
};
