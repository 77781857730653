import { Modal } from 'react-bootstrap';
import { AddIntegrationForm } from './add-integration-form';
import { TeamsLink } from '../shared/links/teams-link';

export const AddEditIntegrationModal = props => {
    const { onClose, onSubmit, show } = props;

    return (
        <Modal show={show} onHide={onClose} size={'xl'}>
            <Modal.Header closeButton>
                <Modal.Title>Add New Integration</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    This form will set up a new integration. If you need assistance, click here to chat with{' '}
                    <TeamsLink username={'john3s@worldpac.com'} name={'John Sanders'} />
                </p>
                <AddIntegrationForm onSubmit={onSubmit} />
            </Modal.Body>
        </Modal>
    );
};
