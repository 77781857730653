import '../../types';

/**
 *
 * @param {ApiVehicle} vehicle
 * @returns {string}
 */
export const getFlatListVehicleDesc = vehicle => {
    if (!vehicle) return '';

    const [vehicleTypeLabel, vehicleTypeValue] = getVehicleTypeIdentifier(vehicle);

    return `${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.submodel} (${vehicleTypeLabel}: ${vehicleTypeValue})`;
};

/**
 * Get vehicle description with submodel and the type of vehicle
 * @param {ApiVehicle} vehicle
 * @returns {[vehicleDescription: string,[vehicleTypeLabel: string, vehicleTypeDesc: string|number]]}
 */
export const getVehicleDescAndType = vehicle => {
    const defaultVehicleDescAndType = ['', ['', '']];

    if (vehicle) {
        const vehicleDesc = `${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.submodel}`;
        const vehicleType = getVehicleTypeIdentifier(vehicle);

        return [vehicleDesc, vehicleType];
    } else {
        return defaultVehicleDescAndType;
    }
};

/**
 * Returns the vehicle type label and value from a vehicle, so it can be displayed in the UI and the user
 * will know what kind of vehicle it is. e.g. Plate: SAUSAGE/TX, or ACES: 123456, or VIN: ABC123466547D
 * @param {ApiVehicle} vehicle
 * @returns {[vehicleTypeLabel: string, vehicleTypeDecs: string|number]}
 */
export const getVehicleTypeIdentifier = vehicle => {
    let defaultVehicleIdentifier = ['', ''];

    if (vehicle.vin) {
        return ['VIN', vehicle.vin];
    } else if (vehicle.licensePlate && vehicle.licensePlateState) {
        return ['Plate/ST', `${vehicle.licensePlate}/${vehicle.licensePlateState}`];
    } else if (vehicle.baseVehicleId) {
        return [`ACES ID`, vehicle.baseVehicleId];
    } else {
        return defaultVehicleIdentifier;
    }
};
