import React, { useState } from 'react';
import axios from 'axios';
import NavMenu from '../nav-menu';
import { useForm } from 'react-hook-form';

export const Login = props => {
    const { register, handleSubmit } = useForm();
    const [loginError, setLoginError] = useState(null);

    const handleReactFormSubmit = (data, event) => {
        const { username, password } = data;

        if (username.length > 0 && password.length > 0) {
            axios
                .post('/api/login', { username, password })
                .then(() => {
                    window.location = '/';
                })
                .catch(({ message }) => {
                    setLoginError('Invalid username or password. Please check your credentials and try again.');
                });
            event.target.reset();
        }
    };

    return (
        <>
            <NavMenu />
            <div id="login-box">
                <h2 className={'mb-4'}>SMS Demo Login</h2>
                <form noValidate onSubmit={handleSubmit(handleReactFormSubmit)}>
                    {loginError && <div className={'alert alert-danger'}>{loginError}</div>}
                    <div className={'form-floating w-75'}>
                        <input
                            type={'username'}
                            className={'form-control'}
                            {...register('username', { required: true })}
                            id={'username'}
                            placeholder={'Username'}
                            autoFocus
                        />
                        <label htmlFor={'floatingInput'}>Username</label>
                    </div>
                    <div className={'form-floating w-75'}>
                        <input
                            type={'password'}
                            className="form-control"
                            {...register('password', { required: true })}
                            id={'password'}
                            placeholder={'Password'}
                        />
                        <label htmlFor={'floatingPassword'}>Password</label>
                    </div>

                    <button className="btn btn-primary btn-lg w-75 d-flex justify-content-center" type="submit">
                        Login
                    </button>

                    <p className="mt-2 mb-3 text-body-secondary">&copy; {new Date().getFullYear()} WORLDPAC, Inc.</p>
                </form>
            </div>
        </>
    );
};
