import React, { useEffect, useState } from 'react';
import axios from 'axios';
import IntegrationList from '../integration/integration-list';
import { AddEditIntegrationModal } from '../integration/add-edit-integration-modal';
import { Button } from 'react-bootstrap';

export const Settings = () => {
    const [integrations, setIntegrations] = useState([]);
    const [showAddEditForm, setShowAddEditForm] = useState(false);

    useEffect(() => {
        getIntegrations();
    }, []);

    const getIntegrations = () => {
        axios
            .get('/api/integrations')
            .then(response => {
                setIntegrations(response.data.data);
            })
            .catch(e => console.log('Error : ', e));
    };

    const handleAddIntegration = formData => {
        axios
            .post('/api/integrations', formData)
            .then(() => {
                getIntegrations();
            })
            .catch(({ message }) => {
                console.error(message.message);
            })
            .finally(() => {
                handleCloseAddEditForm();
            });
    };

    const handleDeleteIntegration = integration => () => {
        axios
            // TODO: change this API to use the ID of the integration, instead of the name
            .delete(`/api/integrations/${integration.name}`)
            .then(() => {
                getIntegrations();
            })
            .catch(({ message }) => {
                console.error(message.message);
            });
    };

    const handleShowAddEditForm = () => {
        setShowAddEditForm(true);
    };

    const handleCloseAddEditForm = () => {
        setShowAddEditForm(false);
    };

    return (
        <>
            <div className="d-flex justify-content-between mb-3">
                <h2>Integrations</h2>
                <Button className={''} variant={'success'} onClick={handleShowAddEditForm} size={'sm'}>
                    + Add
                </Button>
            </div>
            <div className="panel">
                <IntegrationList integrations={integrations} onDelete={handleDeleteIntegration} />
                <AddEditIntegrationModal show={showAddEditForm} onSubmit={handleAddIntegration} onClose={handleCloseAddEditForm} />
            </div>
        </>
    );
};
