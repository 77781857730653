import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Tooltip } from 'react-bootstrap';
import { InfoIcon } from '../shared/info-icon';
import { companies, createPunchoutUrls } from '../../shared/constants';

export const AddIntegrationForm = props => {
    const { setValue, register, handleSubmit } = useForm();
    const defaultCompany = companies[0];
    setValue('country', defaultCompany.name);
    setValue('apiKey', defaultCompany.apiKey);
    const [showCustomerPunchoutUrl, setShowCustomerPunchoutUrl] = useState(false);

    const handleReactFormSubmit = (data, event) => {
        if (data.customPunchoutUrl) {
            data.createPunchoutUrl = data.customPunchoutUrl;
        }
        console.log(data);
        props.onSubmit(data);
        event.target.reset();
    };

    const handlePunchoutUrlChange = event => {
        const selected = event.target.value;

        if (selected === 'custom') {
            setShowCustomerPunchoutUrl(true);
        } else {
            setShowCustomerPunchoutUrl(false);
        }
    };

    const handleCompanyChange = event => {
        const selectedCompanyValue = event.target.value;
        const company = companies.find(c => c.value === selectedCompanyValue);
        setValue('country', company.country);
        setValue('apiKey', company.apiKey);
    };

    return (
        <form noValidate onSubmit={handleSubmit(handleReactFormSubmit)} className={'row g-3'}>
            <input id={'country'} {...register('country', { required: true })} type={'hidden'} />
            <input id={'apiKey'} {...register('apiKey', { required: true })} type={'hidden'} />
            <div className={'col-md-12'}>
                <label className={'fw-bold'} htmlFor={'name'}>
                    Name
                </label>
                <input
                    id={'name'}
                    {...register('name', { required: true })}
                    placeholder={'Name'}
                    type="text"
                    required
                    className={'form-control'}
                />
            </div>
            <div className={'col-md-4'}>
                <label className={'fw-bold'} htmlFor="accountNo">
                    Account #
                </label>
                <input
                    id={'accountNo'}
                    {...register('accountNo', { required: true })}
                    placeholder={'WORLDPAC Account #'}
                    type="text"
                    required
                    className="form-control"
                />
            </div>
            <div className={'col-md-4'}>
                <label className={'fw-bold'} htmlFor="username">
                    Username
                </label>
                <input
                    id={'username'}
                    {...register('username', { required: true })}
                    placeholder={'Email address'}
                    type="text"
                    required
                    className="form-control"
                />
            </div>
            <div className={'col-md-4'}>
                <label className={'fw-bold'} htmlFor="apiToken">
                    API Token
                </label>
                <input
                    id={'apiToken'}
                    {...register('apiToken', { required: true })}
                    placeholder={'Generated in speedDIAL Settings'}
                    type="text"
                    required
                    className="form-control"
                />
            </div>
            <div className={'col-md-4 col-lg-3'}>
                <label className={'fw-bold'} htmlFor={'country'}>
                    Company <InfoIcon text={"Ensure your user's environment matches this setting"} />
                </label>
                <select
                    id={'company'}
                    {...register('company', { required: true })}
                    required
                    className="form-select"
                    onChange={handleCompanyChange}
                >
                    {companies.map(company => {
                        return <option value={company.value}>{company.name}</option>;
                    })}
                </select>
            </div>
            <div className={'col-md-8 col-lg-9'}>
                <label className={'fw-bold'} htmlFor={'createPunchoutUrl'}>
                    Punchout URL
                </label>
                <select
                    className={'form-select'}
                    id={'createPunchoutUrl'}
                    {...register('createPunchoutUrl', { required: true })}
                    onChange={handlePunchoutUrlChange}
                >
                    {createPunchoutUrls.map((i, index) => (
                        <option selected={index === 0} value={i.url}>{`(${i.env}) ${i.url}`}</option>
                    ))}
                    <option value={'custom'}>Custom...</option>
                </select>
            </div>
            {showCustomerPunchoutUrl && (
                <div className={'col-sm-12'}>
                    <label className={'fw-bold'} htmlFor={'customPunchoutUrl'}>
                        Custom Punchout URL
                    </label>
                    <input
                        id={'customPunchoutUrl'}
                        {...register('customPunchoutUrl', { required: true })}
                        placeholder={'e.g. https://speeddial.worldpac.com/punchout/v1/create'}
                        type="url"
                        required
                        className="form-control"
                    />
                </div>
            )}
            <div className={'d-flex justify-content-end'}>
                <Button type={'submit'} variant={'primary'}>
                    Add Integration
                </Button>
            </div>
        </form>
    );
};
